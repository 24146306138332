import React from "react";
import StripePaymentForm from "./StripePaymentForm";

export default function StripeContainer({
  order,
  handleCheckoutDataChange,
  setIsAddressComplete,
  setIsLinkAuthenticationComplete,
  isPaying,
  billingAddress,
  setBillingAddress,
  setIsBillingAndShippingSame,
  isBillingAndShippingSame,
  setPaymentMethod,
  paymentMethod,
}) {
  return (
    <StripePaymentForm
      order={order}
      setIsAddressComplete={setIsAddressComplete}
      handleCheckoutDataChange={handleCheckoutDataChange}
      setIsLinkAuthenticationComplete={setIsLinkAuthenticationComplete}
      isPaying={isPaying}
      billingAddress={billingAddress}
      setBillingAddress={setBillingAddress}
      setIsBillingAndShippingSame={setIsBillingAndShippingSame}
      isBillingAndShippingSame={isBillingAndShippingSame}
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
    />
  );
}
