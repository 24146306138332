import React from "react";
import { NavLink } from "react-router-dom";

const Review = () => {
  return (
    <div className='edit-profile-main'>
        <h3>My Review</h3>
        <div className="edit-profile-content">
            <div className="container">
                <div className='name-field'>
                    No Review Yet
                </div>
                <NavLink to="/"><div className="profile-changes-btn">
                    <button>Continue Shopping</button>
                    </div></NavLink>
            </div>
        </div>
    </div>
  );
};

export default Review;
