import React from 'react'

const PaymentOptions = () => {
  return (
    <div className='edit-profile-main'>
        <h3>Payment Options</h3>
        <div className="edit-profile-content">
                <div className="container">
                    <div className='name-field'>
                        <p>No Payment Options Right Now</p>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default PaymentOptions