import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const Whychooseus = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const whyUseInfo = [
    "Our vast inventory ensures you'll find exactly what you're looking for, from the latest trends to timeless essentials.",
    "Shop with confidence knowing that every item meets our rigorous standards for quality and authenticity.",
    "Enjoy a seamless shopping experience with user-friendly navigation, secure checkout, and prompt delivery.",
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data.length > 4 ? 4 : data.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow: data.length > 4 ? 4 : data.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow: data.length > 2 ? 2 : data.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/choose/get`,
    })
      .then((res) => {
        setData(res.data.data);
        setLoading(true);
      })
      .catch((e) => console.log(e.message));
  }, []);

  return (
    <>
      {data.length > 0 ? (
        <div className="pb-12 pt-2">
          <div className="container">
            <div className="text-center text-p text-[26px] font-bold">
              <p>
                Why <span className="text-green">Choose Us ?</span>
              </p>
            </div>
          </div>

          {/* Section */}

          <div className="main-banner">
            <div className="container">
              <div className="text-center mx-9 sm:mx-0 mt-12">
                {loading ? (
                  <div className="flex flex-wrap gap-12 items-center justify-center text-center">
                    {data.map((item, id) => (
                      <Link
                        to="/about"
                        className="flex items-center flex-col gap-3 group"
                        key={id}
                      >
                        <div className="rounded-md bg-g h-12 w-12 flex items-center justify-center shrink-0 group-hover:translate-y-[-8px] transition-transform">
                          <img
                            src={item.image}
                            alt="ico"
                            className="object-contain h-8 w-8"
                          />
                        </div>
                        <p className="group-hover:text-p transition-colors">
                          {item.title}
                        </p>
                      </Link>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Whychooseus;
