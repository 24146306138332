import React, { useEffect } from "react";
import MainBanner from "../Components/BodyComponents/mainBanner/MainBanner";
import FeaturedProducts from "../Components/BodyComponents/featuredProducts/FeaturedProducts";
import OfferProducts from "../Components/BodyComponents/featuredProducts/OfferProducts";
import Secondbanner from "../Components/BodyComponents/secondbanner/Secondbanner";
import Whychooseus from "../Components/BodyComponents/Whychooseus/Whychooseus";
import Secondfeatured from "../Components/BodyComponents/secondfeatured/Secondfeatured";
import Snackbar from "../Components/BodyComponents/snackbar/snackbar";
import { useLocation } from "react-router-dom";
import ReactHelmet from "../Components/ReactHelmet/ReactHelmet";

const Home = () => {
  const [open, setOpen] = React.useState(false);
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setOpen(state.openSnackbar);
    }

    setTimeout(() => {
      window.history.replaceState({}, document.title);
    }, 1000);
  }, [state]);

  return (
    <>
      <ReactHelmet
        title="Nepali Garden"
        metaDescription="Welcome to Nepaligarden.com, your one-stop online destination for Nepalese groceries and handicrafts! We are a premier online store dedicated to providing a convenient shopping experience for all your Nepalese food and artisanal needs."
        keywords="Nepali Garden, nepaligarden.com, Online Shopping, Store, Grocery, Food, Artisan, Sweet Candy, Grains, Pau, Cereals, Handicrafts, Utensils, Oil, Ghee, Flours, Rice, Spices, Pickles, Fermented Food, Noodles, Snacks, Tea, Beverage"
      />
      <MainBanner />
      <OfferProducts />
      <FeaturedProducts />
      {/* <Secondbanner /> */}
      <Whychooseus />
      {/* <Secondfeatured /> */}
      <Snackbar
        open={open}
        setOpen={setOpen}
        message={state?.message ? state?.message : "Login successful"}
      />
    </>
  );
};
export default Home;
