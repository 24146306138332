import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Loginimg from "../../Components/assets/images/login.png";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import ReactCodeInput from "react-code-input";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import ReactHelmet from "../../Components/ReactHelmet/ReactHelmet";

const Codeverification = (props) => {
  const { state } = useLocation();
  const [token, setToken] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [codeLoader, setCodeLoader] = useState(false);

  const [valueInput, setValueInput] = useState(false);
  const [valid, setValid] = useState(true);

  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const postVerify = async (e) => {
    e.preventDefault();
    if (token === "") {
      setResponse({
        success: null,
        error: "Please enter your verification code",
      });
    } else {
      setLoading(true);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/user/checkVerificationCode`,
        data: {
          verification_code: token,
          email: props.email,
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            navigate("/confirmnewpassword");
            setResponse({
              success: "Your email has been verified",
              error: null,
            });
            setToken("");
          } else {
            setResponse({
              success: null,
              error: "Please enter a valid verification code",
            });
          }
        })
        .catch((error) => {
          setResponse({
            success: null,
            error: "Server error",
          });
        });
      setLoading(false);
    }

    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  const postReset = async (e) => {
    e.preventDefault();
    setCodeLoader(false);
    setLoading(true);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/user/sendVerificationCode`,
      data: {
        email: props.email,
      },
    })
      .then((res) => {
        if (res.data.status === true) {
          enqueueSnackbar("Code has been resent to your email.", {
            variant: "success",
          });

          setTimeout(() => {
            setCodeLoader(false);
          }, 30000);
        } else {
          enqueueSnackbar("Failed to resend code. Try again", {
            variant: "error",
          });

          setCodeLoader(false);
        }
      })
      .catch((error) => {
        setResponse({
          success: null,
          error: "Error",
        });

        setCodeLoader(false);
      });
    setLoading(false);

    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  const handleValueInput = (e) => {
    setToken(e);
  };

  return (
    <div className="code-verification-main">
      <ReactHelmet title="Code verification - Nepali Garden" />
      <div className="container">
        <div className="flex gap-4">
          <div className="hidden md:block w-1/2">
            <div className="login-image">
              <img src={Loginimg} alt="verify" />
            </div>
          </div>
          <div className="w-full md:w-1/2 border shadow-sm rounded p-6">
            <div className="login-title">
              <h6>Verify with Nepali Garden</h6>
              <p>
                Already register?
                <Link to="/login" className="underline-click pl-1">
                  Click here to sign in
                </Link>
              </p>
            </div>
            {response.error ? (
              <>
                <Alert severity="error">{response.error}</Alert>
              </>
            ) : null}
            <div className="code-verify">
              <div className="row">
                <ReactCodeInput
                  name="resetPassword"
                  inputMode="numeric"
                  fields={4}
                  type="text"
                  onChange={(e) => handleValueInput(e)}
                  isValid={valid}
                />
              </div>
              <div className="button">
                <LoadingButton
                  className="btn"
                  loading={loading}
                  variant="outlined"
                  onClick={postVerify}
                >
                  Verify Your Code
                </LoadingButton>
              </div>

              <div className="btn mt-4">
                <p
                  onClick={postReset}
                  disabled={codeLoader}
                  className="cursor-pointer hover:underline "
                >
                  Resend Link
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Codeverification;
