import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Parse from "html-react-parser";
import axios from "axios";
import { Alert, LoadingButton } from "@mui/lab";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useSnackbar } from "notistack";
import { ValidEmail } from "../helpers/Validators";
import ReactHelmet from "../Components/ReactHelmet/ReactHelmet";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [response, setResponse] = useState({
    success: null,
    error: null,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [validationMessage, setValidationMessage] = useState({
    name: null,
    email: null,
    message: null,
  });

  const [contact, setContact] = useState([]);
  useEffect(() => {
    setContactLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/contact-page/get`,
    })
      .then((res) => {
        setContact(res.data.data);
        setContactLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setContactLoading(false);
      });
  }, []);

  const postSubmit = async (e) => {
    e.preventDefault();

    if (name === "") {
      setValidationMessage({
        name: "Name cannot be empty",
        email: null,
        message: null,
      });
      return;
    } else if (email === "") {
      setValidationMessage({
        name: null,
        email: "Email Cannot be empty",
        message: null,
      });
      return;
    } else if (ValidEmail(email) === false) {
      setValidationMessage({
        name: null,
        email: "Invalid Email",
        message: null,
      });
      return;
    }

    if (message === "") {
      setValidationMessage({
        name: null,
        email: null,
        message: "Message cannot be empty",
      });
      return;
    } else {
      setValidationMessage({
        name: null,
        email: null,
        message: null,
      });

      // if (name === "" || email === "" || message === "") {
      //   setResponse({
      //     success: null,
      //     error: "Empty field detected",
      //   });
      // }

      setLoading(true);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/contactMessage/store`,
        data: {
          name: name,
          email: email,
          message: message,
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            enqueueSnackbar(
              "Thank You for contacting. We will get back to you soon .",
              {
                variant: "success",
              },
            );
            setName("");
            setEmail("");
            setMessage("");
          } else {
            setResponse({
              success: null,
              error: res.data.message,
            });
          }
        })
        .catch((error) => {
          setResponse({
            success: null,
            error: "Server Error",
          });
        });
      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  return (
    <div className="contact_page py-16">
      <ReactHelmet title="Contact - Nepali Garden" />
      <div className="text-center text-p text-[26px] font-bold pb-8">
        <p>
          Contact <span className="text-green">Us</span>
        </p>
      </div>
      <div className="contact_map container">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1548765.3523386961!2d-73.979681!3d40.697488!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2snp!4v1717089326107!5m2!1sen!2snp"
          width="100%"
          height="400"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="contact_info container pt-8">
        <div className="flex flex-col md:flex-row gap-2">
          <div className="w-full md:w-1/2 lg:w-1/3">
            <ul className="basic_info">
              <li className="info_heading pb-4">
                <h5>contact us</h5>
              </li>
              {contactLoading ? (
                <Stack spacing={1}>
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={40} />
                </Stack>
              ) : (
                <>
                  <li className="flex gap-2 items-center text-lg py-2">
                    <i class="fa-solid fa-phone-flip"></i>
                    <h5>{contact[0]?.phone}</h5>
                  </li>
                  <li className="flex gap-2 items-center text-lg py-2">
                    <i class="fa-solid fa-envelope"></i>
                    <h5>{contact[0]?.email}</h5>
                  </li>
                  <li className="flex gap-2 items-center text-lg py-2">
                    <i class="fa-solid fa-location-dot"></i>
                    <h5>{contact[0]?.address}</h5>
                  </li>
                  <div className="social_icon_contact">
                    <Link to="#">
                      {" "}
                      <i class="fa-brands fa-youtube"></i>
                    </Link>
                    <Link to="#">
                      {" "}
                      <i class="fa-brands fa-twitter"></i>
                    </Link>
                    <Link to="#">
                      {" "}
                      <i class="fa-brands fa-facebook-f"></i>
                    </Link>
                    <Link to="#">
                      {" "}
                      <i class="fa-brands fa-instagram"></i>
                    </Link>
                  </div>
                </>
              )}
            </ul>
          </div>

          <div className="w-full md:w-1/2 lg:w-2/3">
            <div className="basic_info">
              <form>
                {response.error ? (
                  <>
                    <Alert severity="error">{response.error}</Alert>
                  </>
                ) : null}
                {response.success ? (
                  <>
                    <Alert severity="success">{response.success}</Alert>
                  </>
                ) : null}

                <div className="">
                  <label htmlFor="exampleInputName" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={
                      validationMessage.name
                        ? {
                            border: "1px solid red",
                          }
                        : null
                    }
                  />
                </div>
                {validationMessage.name ? (
                  <span className="error error-message">
                    {validationMessage.name}
                  </span>
                ) : null}

                <div className="mt-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={
                      validationMessage.email
                        ? {
                            border: "1px solid red",
                          }
                        : null
                    }
                  />
                </div>
                {validationMessage.email ? (
                  <span className="error error-message">
                    {validationMessage.email}
                  </span>
                ) : null}

                <div className="mb-3">
                  <div class="mt-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      class="form-label"
                    >
                      Message
                    </label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      style={
                        validationMessage.message
                          ? {
                              border: "1px solid red",
                            }
                          : null
                      }
                    />
                  </div>
                  {validationMessage.message ? (
                    <span className="error error-message">
                      {validationMessage.message}
                    </span>
                  ) : null}
                </div>

                <div className="button btn-submit">
                  <LoadingButton
                    className="btn"
                    type="submit"
                    loading={loading}
                    variant="outlined"
                    onClick={postSubmit}
                  >
                    Submit
                  </LoadingButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
