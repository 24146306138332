import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeletoncard from "../skeletoncard/skeletoncard";
import { addToCart } from "../../../Services/Action/Action";
import { addToWishlist } from "../../../Services/Action/WishlistAction";
import { useSnackbar } from "notistack";

const FeaturedProducts = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handler = (e, product) => {
    dispatch(addToCart(product));
    enqueueSnackbar("Item added to cart", {
      variant: "success",
    });
  };

  const handlerWish = (e, product) => {
    e.preventDefault();
    dispatch(addToWishlist(product));
    enqueueSnackbar("Item added to wishlist", {
      variant: "success",
    });
  };

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/getFeature`,
    })
      .then((res) => {
        let response = res.data.products.data;
        setProducts(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);

  return (
    <div className="feature-main pb-8 sm:pb-16">
      <div className="container">
        <div className="text-center text-p text-[26px] font-bold">
          <p>
            Our <span className="text-green">Products</span>
          </p>
        </div>

        {loading ? (
          <div className="mt-4 sm:mt-8 grid grid-cols-2 min-[700px]:grid-cols-4 xl:grid-cols-5 gap-4">
            <Skeletoncard />
          </div>
        ) : (
          <>
            <div className="mt-4 grid grid-cols-2 min-[700px]:grid-cols-4 xl:grid-cols-5 sm:gap-4 sm:mt-8">
              {products.slice(0, 30).map((product, id) => {
                return (
                  <div
                    className="group bg-white sm:shadow-md duration-300 hover:bg-t border sm:border-zinc-200 sm:rounded flex flex-col gap-4"
                    key={id}
                  >
                    <div className="w-full aspect-square relative overflow-hidden px-1 pt-1 sm:px-2 sm:pt-2">
                      <NavLink to={`/product/${product.slug}`}>
                        <img
                          src={product.image}
                          alt="featureproducts"
                          className="border border-stone-50 rounded-sm h-full w-full object-cover"
                        />
                      </NavLink>
                    </div>

                    <NavLink
                      to={`/product/${product.slug}`}
                      className={"flex-1 px-2"}
                    >
                      <p className="text-sm xs:text-base font-medium text-ellipsis line-clamp-2 text-black group-hover:text-p duration-300 text-center">
                        {product.name}
                      </p>
                    </NavLink>

                    <p className="cart-content-price text-base font-extrabold text-p text-center px-">
                      $ {product.price.toFixed(2)}
                    </p>

                    {Number(product.in_stock) === 1 ? (
                      <div className="rounded bg-green transition-colors duration-500 hover:bg-[#15632f] mx-2 mb-2">
                        <button
                          type="button"
                          className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm"
                          onClick={(e) => handler(e, product)}
                        >
                          <i className="fas fa-shopping-cart pe-1"></i>
                          ADD TO CART
                        </button>
                      </div>
                    ) : (
                      <div className="rounded bg-[#8c1818] mx-2 mb-2">
                        <div className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm">
                          Out of Stock
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex w-full items-center justify-center">
              <NavLink to="/filter">
                <button className="h-10 mt-12 px-6 text-white bg-green border border-green hover:border-hoverGreen hover:bg-hoverGreen transition-colors rounded-md font-bold">
                  View All
                </button>
              </NavLink>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeaturedProducts;
