import React from "react";
import { NavLink } from "react-router-dom";

const LeftBarSide = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-3">
        <h4 className="text-2xl font-bold text-p">My Account</h4>
        <ul className="flex flex-col gap-2">
          <li className="w-fit">
            <NavLink
              to="/profile"
              className="hover:underline underline-offset-2"
            >
              <p>My Profile</p>
            </NavLink>
          </li>

          <li className="w-fit">
            <NavLink
              to="/profile/myorderhistory"
              className="hover:underline underline-offset-2"
            >
              <p>My Orders</p>
            </NavLink>
          </li>
          {/* <li>
              <NavLink
                to="/profile/addressinformation"
                className="link-profile"
              >
                <p>Address Information</p>
              </NavLink>
            </li> */}
          {/* <li>
              <NavLink to="/profile/paymentoptions" className="link-profile">
                <p>My Payment Options</p>
              </NavLink>
            </li> */}
          {/* <li>
              <NavLink to="/profile/voucher" className="link-profile">
                <p>Vouchers</p>
              </NavLink>
            </li> */}
        </ul>
      </div>
      {/* <div className="manage-profile-main col-4 col-lg-12">
          <h4>My Orders</h4>
          <ul>
            <li>
              <NavLink to="/profile/myreturns" className="link-profile">
                <p>My Returns</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile/cancellations" className="link-profile">
                <p>My Cancellations</p>
              </NavLink>
            </li>
          </ul>
        </div> */}
      {/* <div className="manage-profile-main col-4 col-lg-12">
          <ul>
            <li>
              <NavLink to="/profile/myreview" className="link-profile">
                {" "}
                <p>My Reviews</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile/mywishlist" className="link-profile">
                <p>My Wishlist</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile/myorderhistory" className="link-profile">
                <p>My Order History</p>
              </NavLink>
            </li>
          </ul>
        </div> */}
    </div>
  );
};

export default LeftBarSide;
