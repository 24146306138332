import React, { useEffect, useState } from "react";
import Payment1 from "../assets/images/applepay.png";
import Discover from "../assets/images/discover.png";
import Mastercard from "../assets/images/mastercard.png";
import Amex from "../assets/images/amex.png";
import Payment4 from "../assets/images/paypal.png";
import Visa from "../assets/images/visa.png";
import Payment6 from "../assets/images/zelle.png";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { ValidEmail } from "../../helpers/Validators";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [contactInfo, setContactInfo] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const subscribe = () => {
    if (email === "") {
      return;
    }

    if (ValidEmail(email) === false) {
      enqueueSnackbar("Invalid Email", {
        variant: "error",
      });

      return;
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/enquiry/store`,
      data: {
        email: email,
      },
    })
      .then((res) => {
        if (res.data.status) {
          enqueueSnackbar("Thank you for subscribing", {
            variant: "success",
          });

          window.scroll(0, 0);
          setEmail("");
        } else {
          enqueueSnackbar("Failed to subscribe. Try again later.", {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar("Failed to subscribe. Try again later.", {
          variant: "error",
        });
      });
  };

  // Fetch contact information
  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/contact-page/get`,
    })
      .then((res) => setContactInfo(res.data.data[0] ? res.data.data[0] : null))
      .catch((e) => console.log(e.message));
  }, []);

  return (
    <>
      <div className="bg-bgGray text-black pt-4">
        <div className="top_footer container px-4 md:px-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 mb-4">
            <div className="pt-[20px] md:col-span-2">
              <ul className="mt-0 p-0 font-['Poppins']">
                <li className="text-base capitalize pt-[5px] list-none">
                  {contactInfo?.address}
                </li>
                <li className="text-base capitalize pt-[5px] list-none">
                  +1 720-653-1409
                </li>
                <li className="text-base capitalize pt-[5px] list-none">
                  +1 646-257-0968
                </li>
                <li className="text-base pt-[5px] list-none">
                  {contactInfo?.email}
                </li>
              </ul>
            </div>
            <div className="pt-[20px]">
              <div className="font-bold text-lg">Useful</div>
              <ul className="mt-0 p-0 font-['Poppins']">
                <li className="text-base capitalize pt-[5px] list-none">
                  <NavLink
                    to="/"
                    className="text-black hover:underline underline-offset-4"
                  >
                    Home
                  </NavLink>
                </li>
                <li className="text-base capitalize pt-[5px] list-none">
                  <NavLink
                    to="/about"
                    className="text-black hover:underline underline-offset-4"
                  >
                    about us
                  </NavLink>
                </li>
                <li className="text-base capitalize pt-[5px] list-none">
                  <NavLink
                    to="/contact"
                    className="text-black hover:underline underline-offset-4"
                  >
                    contact us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="pt-[20px]">
              <div className="font-bold text-lg">Legal</div>
              <ul className="mt-0 p-0 font-['Poppins']">
                <li className="text-base capitalize pt-[5px] list-none">
                  <NavLink
                    to="/termsandconditions"
                    className="text-black hover:underline underline-offset-4"
                  >
                    Terms
                  </NavLink>
                </li>
                <li className="text-base capitalize pt-[5px] list-none">
                  <NavLink
                    to="/return-policy"
                    className="text-black hover:underline underline-offset-4"
                  >
                    return policy
                  </NavLink>
                </li>

                <li className="text-base capitalize pt-[5px] list-none">
                  <NavLink
                    to="/privacy-policy"
                    className="text-black hover:underline underline-offset-4"
                  >
                    privacy policy
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="pt-[20px]">
              <div className="social_icon mt-0" style={{ marginTop: 0 }}>
                <a href={contactInfo?.youtube} target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-tiktok"></i>
                </a>

                {/* <a href={contactInfo?.twitter} target="_blank" rel="noreferrer">
                  <i className="fa-brands fa-twitter"></i>
                </a> */}

                <a
                  href={contactInfo?.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </a>

                <a
                  href={contactInfo?.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </div>

              <ul className="mt-0 p-0 font-['Poppins']">
                <li className="py-2.5">Sign up for our newsletter</li>
                <li>
                  <input
                    type="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="rounded px-2.5 text-black text-sm h-8 w-full"
                  />

                  <button
                    type="submit"
                    onClick={subscribe}
                    className="border-none transition-colors bg-g text-black text-sm mt-2.5 px-4 py-1 uppercase rounded hover:bg-black hover:text-g"
                  >
                    submit
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="map container">
          <iframe
            title="Maps"
            src={contactInfo?.google_map}
            width="100%"
            height="100%"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div> */}

        <div className="flex flex-col gap-4 justify-between container sm:items-end sm:flex-row pb-4 pt-2">
          <div className="flex flex-col gap-1">
            <p className="text-sm">Supported payment methods:</p>
            <div className="flex items-center gap-1.5 flex-wrap">
              {/* <div className="h-6">
              <img
                src={Payment1}
                alt="payment method"
                className="h-full w-full object-contain"
              />
            </div> */}
              <div className="h-5">
                <img
                  src={Amex}
                  alt="amex"
                  className="h-full w-full object-contain"
                />
              </div>
              <div className="h-6">
                <img
                  src={Discover}
                  alt="payment method"
                  className="h-full w-full object-contain"
                />
              </div>
              <div className="h-6">
                <img
                  src={Mastercard}
                  alt="payment method"
                  className="h-full w-full object-contain"
                />
              </div>
              {/* <div className="h-6">
              <img
                src={Payment4}
                alt="payment method"
                className="h-full w-full object-contain"
              />
            </div> */}
              <div className="h-6">
                <img
                  src={Visa}
                  alt="payment method"
                  className="h-full w-full object-contain"
                />
              </div>
              {/* <div className="h-6">
              <img
                src={Payment6}
                alt="payment method"
                className="h-full w-full object-contain"
              />
            </div> */}
            </div>
          </div>

          <p className="sm:text-center text-xs">
            Copyright &copy; {new Date().getFullYear()}, Nepali Garden |
            Developed by: Kiseki Tech
          </p>
        </div>
      </div>
    </>
  );
};
export default Footer;
