import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import ZoomImage from "../../Components/Helper/ZoomImage";
import { addToCart } from "../../Services/Action/Action";
// import { addToWishlist } from "../../Services/Action/WishlistAction";
import ReactHelmet from "../../Components/ReactHelmet/ReactHelmet";

const Detailpage = () => {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [count, setCount] = useState(1);

  useEffect(() => {
    if (params.slug) {
      const fetchProductDetails = async () => {
        try {
          const productResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/product/get/${params.slug}`,
          );
          const similarProductsResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/product/getSimilarProducts/${params.slug}`,
          );

          setProducts(productResponse.data.data.product);
          setSimilarProducts(similarProductsResponse.data.data.similarProducts);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching product details:", err);
          setLoading(false);
          navigate("/404");
        }
      };

      fetchProductDetails();
    }
  }, [params.slug]);

  const handler = (e, get) => {
    dispatch(addToCart(get, count));
    enqueueSnackbar("Item added to cart", { variant: "success" });
  };

  // const handlerWish = (e, get) => {
  //   e.preventDefault();
  //   dispatch(addToWishlist(get));
  //   enqueueSnackbar("Item added to wishlist", { variant: "success" });
  // };

  const minus = () =>
    setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value);

    if (value > 25) {
      setCount(25);
      enqueueSnackbar("You cannot add more than 25 items to cart", {
        variant: "warning",
      });
      return;
    }

    if (isNaN(value) || value < 0) {
      setCount(0);
      return;
    }

    setCount(value);
  };

  const add = () => {
    if (count < 25) {
      setCount((prevCount) => prevCount + 1);
    } else {
      enqueueSnackbar("You cannot add more than 25 items to cart", {
        variant: "warning",
      });
    }
  };

  const titleSlug = params.slug.charAt(0).toUpperCase() + params.slug.slice(1);
  const titleSlugArray = titleSlug.split("-");

  if (loading) {
    return (
      <div className="min-h-[70svh] flex flex-col items-center justify-center">
        <ReactHelmet
          title={`${titleSlugArray.join(" ")} - Nepali Garden`}
          metaDescription={products?.detail?.replace(/(<([^>]+)>)/gi, "")}
        />
        <div className="text-center text-p text-[26px] font-bold">
          <p>
            Loading <span className="text-green">Product Details...</span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white py-8">
      <ReactHelmet
        title={`${titleSlugArray.join(" ")} - Nepali Garden`}
        metaDescription={products?.detail?.replace(/(<([^>]+)>)/gi, "")}
      />

      <div className="container flex flex-col gap-8">
        <div className="text-center text-p text-[26px] font-bold">
          <p>
            Product <span className="text-green">Details</span>
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-8 w-full shadow p-4 bg-white border-[1px] border-slate-300 rounded-md">
          <ZoomImage
            smallImage={
              products.image ||
              "https://www.gamudacove.com.my/media/img/default-img.jpg"
            }
            largeImage={
              products.image ||
              "https://www.gamudacove.com.my/media/img/default-img.jpg"
            }
          />

          <div className="w-full flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="text-xl font-bold">{products.name}</p>

              <p className="text-lg tracking-wide text-wrap font-bold text-p">
                ${products.price?.toFixed(2)}
              </p>
            </div>

            {Number(products.in_stock) === 1 ? (
              <div className="flex lg:flex-row flex-col justify-between lg:items-end items-start gap-4">
                <div className="flex flex-col gap-4 items-start">
                  <span className="font-bold text-lg text-green flex flex-row gap-2 items-center">
                    <i className="fas fa-check-circle"></i> In Stock
                  </span>

                  <p className="text-base font-medium tracking-normal">
                    Quantity:
                  </p>

                  <div className="flex flex-row border-[1px] border-slate-500 text-slate-500 *:select-none cursor-pointer *:px-2 *:py-1 rounded overflow-hidden w-fit">
                    <p
                      className="hover:bg-p hover:text-white text-2xl"
                      onClick={minus}
                    >
                      -
                    </p>
                    <input
                      type="text"
                      value={count}
                      onChange={handleInputChange}
                      className="w-12 text-center !p-0 outline-none font-bold text-lg -mt-[1px]"
                    />
                    <p
                      className="hover:bg-green hover:text-white  text-2xl"
                      onClick={add}
                    >
                      +
                    </p>
                  </div>
                </div>

                <button
                  type="button"
                  className="flex flex-row gap-2 items-center bg-green text-white px-6 py-2 rounded w-fit h-fit hover:bg-hoverGreen transition-colors duration-300 text-base"
                  onClick={(e) => handler(e, products)}
                >
                  <i className="fas fa-shopping-cart"></i>
                  ADD TO CART
                </button>
              </div>
            ) : (
              <span className="font-bold text-lg text-p flex flex-row gap-2 items-center">
                <i className="fas fa-times-circle"></i> Out of Stock
              </span>
            )}

            <hr />

            <div className="flex flex-col gap-2">
              <h5 className="text-xl font-semibold tracking-normal text-s">
                Description
              </h5>
              <p className="text-base tracking-wide text-slate-700 text-wrap">
                {parse(products.detail || "")}
              </p>

              <p className="text-base text-slate-700">
                Net weight: {products.unit_in_gram}
              </p>
            </div>

            {!!products.ingredeints && (
              <div className="flex flex-col gap-2">
                <h6 className="text-base font-medium tracking-normal text-a">
                  Ingredients
                </h6>
                <p className="text-sm tracking-wide text-slate-500">
                  {parse(products.ingredeints || "")}
                </p>
              </div>
            )}
          </div>
        </div>

        {similarProducts.length > 0 && (
          <div>
            <div className="text-center text-p text-[26px] font-bold">
              <p>
                Similar <span className="text-green">Products</span>
              </p>
            </div>
            <div className="mt-8 grid grid-cols-2 min-[700px]:grid-cols-4 lg:grid-cols-5 gap-x-6 gap-y-8">
              {similarProducts.map((product, id) => (
                <div
                  className="group p-2 shadow-md duration-300 bg-white hover:bg-t border border-zinc-200 rounded flex flex-col justify-between gap-2"
                  key={id}
                >
                  <div>
                    <div className="w-full aspect-square relative overflow-hidden">
                      <NavLink to={`/product/${product.slug}`}>
                        <img
                          src={product.image}
                          alt="featureproducts"
                          className="border border-stone-50 rounded-sm h-full w-full object-cover"
                        />
                      </NavLink>
                    </div>
                    <div className="text-center mt-4 mb-1 flex flex-col gap-2">
                      <NavLink to={`/product/${product.slug}`}>
                        <p className="text-sm xs:text-base font-medium text-ellipsis line-clamp-2 text-black group-hover:text-p duration-300">
                          {product.name}
                        </p>
                      </NavLink>
                      <p className="cart-content-price text-base font-extrabold text-p">
                        $ {product.price?.toFixed(2)}
                      </p>
                    </div>
                  </div>
                  {Number(product.in_stock) === 1 ? (
                    <div className="rounded bg-green transition-colors duration-500 hover:bg-[#15632f]">
                      <button
                        type="button"
                        className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm"
                        onClick={(e) => handler(e, product)}
                      >
                        <i className="fas fa-shopping-cart pe-1"></i>
                        ADD TO CART
                      </button>
                    </div>
                  ) : (
                    <div className="rounded bg-[#8c1818]">
                      <div className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm">
                        Out of Stock
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Detailpage;
