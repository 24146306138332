import { CircularProgress, Modal } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import Skeleton from "@mui/material/Skeleton";
import { ValidEmail, ValidContact } from "../../../helpers/Validators";

const EditProfile = ({ user }) => {
  const navigate = useNavigate();
  const [loadingPersonalData, setLoadingPersonalData] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const initialState = {
    name: "",
    email: "",
    phone_no: "",
    dateofbirth: "",
    address: "",
  };

  const [validationMessage, setValidationMessage] = useState({
    name: null,
    email: null,
    phone_no: null,
    dateofbirth: null,
    address: null,
  });
  const [showProfileModal, setShowProfileModal] = useState(false);

  const [profileData, setProfileData] = useState(initialState);

  const logInId = user?.user.id;

  useEffect(() => {
    setLoadingPersonalData(true);
    axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
      url: `${process.env.REACT_APP_API_URL}/user/get/${logInId}`,
    })
      .then((res) => {
        let response = res.data.data.user;
        setProfileData(response);
        setLoadingPersonalData(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingPersonalData(false);
      });
  }, [user]);

  const updateInputHandler = (e) => {
    e.preventDefault();
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const updateProfile = () => {
    if (profileData?.name === "") {
      setValidationMessage({
        name: "Name cannot be empty",
        email: null,
        phone_no: null,
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (profileData?.email === "") {
      setValidationMessage({
        name: null,
        email: "Email cannot be empty",
        phone_no: null,
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (ValidEmail(profileData?.email) === false) {
      setValidationMessage({
        name: null,
        email: "Invalid email address",
        phone_no: null,
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (profileData?.phone_no === "") {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: "Phone number cannot be empty",
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (ValidContact(profileData?.phone_no) === false) {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: "Invalid contact number",
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (profileData?.dateofbirth === "") {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: null,
        dateofbirth: "Date of birth cannot be empty",
        address: null,
      });
      return;
    } else if (profileData?.address === "") {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: null,
        dateofbirth: null,
        address: "Address cannot be null",
      });
      return;
    } else {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: null,
        dateofbirth: null,
        address: null,
      });

      setLoading(true);
      axios({
        method: "POST",
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
        url: `${process.env.REACT_APP_API_URL}/user/update`,
        data: profileData,
      })
        .then((res) => {
          enqueueSnackbar(`Profile has been updated successfully`, {
            variant: "success",
          });
          // setResponse({
          //   success: "profile successfully updated",
          //   error: null,
          // });
          setLoading(false);
          setShowProfileModal(false);
          // $('#exampleModalLabel').modal('hide');
        })
        .catch((err) => {
          console.log(err);
          setResponse({
            success: null,
            error: "server Error !!",
          });
          enqueueSnackbar(`Failed to update profile`, {
            variant: "error",
          });
          setLoading(false);
        });
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <h3 className="text-2xl font-bold text-p">My Profile</h3>
      <div className="bg-[#F7F7F7] p-8 rounded">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col xs:flex-row gap-4">
            <div className="w-full">
              <div className="flex flex-col">
                <label className="text-black font-bold" htmlFor="">
                  Full Name
                </label>
                <p className="text-black">
                  {loadingPersonalData ? (
                    <Skeleton variant="text" height={32} width="100%" />
                  ) : (
                    profileData?.name
                  )}
                </p>
              </div>
            </div>
            <div className="w-full">
              <p className="text-black font-bold">Email Address</p>
              <p className="text-black">
                {loadingPersonalData ? (
                  <Skeleton variant="text" height={32} width="100%" />
                ) : (
                  profileData?.email
                )}
              </p>
            </div>
          </div>

          <div className="flex flex-col xs:flex-row gap-4">
            <div className="w-full">
              <div className="flex flex-col">
                <label className="text-black font-bold" htmlFor="">
                  Mobile
                </label>
                <p className="text-black">
                  {loadingPersonalData ? (
                    <Skeleton variant="text" height={32} width="100%" />
                  ) : (
                    profileData?.phone_no
                  )}
                </p>
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-col">
                <label className="text-black font-bold" htmlFor="">
                  Birthday Date
                </label>
                <div className="text-black">
                  {profileData?.dateofbirth ? (
                    <p>{profileData.dateofbirth}</p>
                  ) : (
                    <Skeleton variant="text" height={32} width="100%" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="flex flex-col">
              <label className="text-black font-bold" htmlFor="">
                Address
              </label>
              <div className="text-black">
                {profileData?.address ? (
                  <p>{profileData.address}</p>
                ) : (
                  <Skeleton variant="text" height={32} width="100%" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="profile-changes-btn"
          style={{ marginTop: "12px", marginBlock: "0px" }}
        >
          <button
            type="button"
            className="button text-black"
            onClick={() => setShowProfileModal(true)}
            style={{ fontSize: "16px" }}
          >
            Edit Profile
          </button>
        </div>

        {/* Profile Modal */}
        <Modal
          open={showProfileModal}
          onClose={() => {
            showProfileModal(false);
          }}
          aria-labelledby="editProfile"
          aria-describedby="editProfile"
        >
          <div className="bg-white rounded p-6 w-80 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header pb-4">
                  <h5 className="text-p text-2xl font-bold">Edit profile</h5>
                </div>
                <div>
                  <form className="flex flex-col gap-3">
                    <div className="">
                      <label htmlFor="exampleInputname" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={profileData?.name}
                        onChange={updateInputHandler}
                        onKeyPress={(event) => {
                          if (!/[A-Z a-z]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        style={
                          validationMessage.name
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                      {validationMessage.name ? (
                        <span className="error error-message">
                          {validationMessage.name}
                        </span>
                      ) : null}
                    </div>
                    <div>
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="email"
                        value={profileData?.email}
                        onChange={updateInputHandler}
                        style={
                          validationMessage.email
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                      {validationMessage.email ? (
                        <span className="error error-message">
                          {validationMessage.email}
                        </span>
                      ) : null}
                    </div>
                    <div>
                      <label
                        htmlFor="exampleInputnumber"
                        className="form-label"
                      >
                        Contact Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputnumber"
                        name="phone_no"
                        maxLength={10}
                        value={profileData?.phone_no}
                        onChange={updateInputHandler}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        style={
                          validationMessage.phone_no
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                    </div>
                    {validationMessage.phone_no ? (
                      <span className="error error-message">
                        {validationMessage.phone_no}
                      </span>
                    ) : null}

                    <div>
                      <label
                        htmlFor="exampleInputnumber"
                        className="form-label"
                      >
                        DOB
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="exampleInputnumber"
                        aria-describedby="numberHelp"
                        name="dateofbirth"
                        value={profileData?.dateofbirth}
                        onChange={updateInputHandler}
                        max={new Date().toISOString().split("T")[0]}
                        style={
                          validationMessage.dob
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                      {validationMessage.dob ? (
                        <span className="error error-message">
                          {validationMessage.dob}
                        </span>
                      ) : null}
                    </div>
                    <div>
                      <label
                        htmlFor="exampleInputnumber"
                        className="form-label"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputnumber"
                        aria-describedby="numberHelp"
                        name="address"
                        value={profileData?.address}
                        onChange={updateInputHandler}
                        style={
                          validationMessage.address
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                      {validationMessage.address ? (
                        <span className="error error-message">
                          {validationMessage.address}
                        </span>
                      ) : null}
                    </div>
                  </form>
                </div>

                <div className="flex gap-2 button flex-col">
                  <LoadingButton
                    type="button"
                    variant="outline"
                    loading={loading}
                    className="btn"
                    onClick={updateProfile}
                  >
                    Save changes
                  </LoadingButton>
                  <LoadingButton
                    loading={loading}
                    variant="outlined"
                    onClick={() => setShowProfileModal(false)}
                  >
                    Cancel
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* <div
          className="modal fade"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Change password
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">
                      old Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">
                      new Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">
                      confirm Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer profile-changes-btn">
                <button type="button" className="button">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default EditProfile;
