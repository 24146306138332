import React from "react";
import { NavLink } from "react-router-dom";
import ReactHelmet from "../Components/ReactHelmet/ReactHelmet";

const Notfound = () => {
  return (
    <>
      <div className="notfound-container">
        <ReactHelmet title="404 - Nepali Garden" metaDescription="Not found" />
        <h1 className="notfound-title">404</h1>
        <p className="notfound-message">
          Oops! The page you are looking for does not exist.
        </p>
        <NavLink to="/" className="notfound-link">
          Go back to Home
        </NavLink>
      </div>
    </>
  );
};
export default Notfound;
