import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import featurelist from "../../Components/BodyComponents/featuredProducts/Featurelist";
import axios from "axios";
import Skeletoncard from "../../Components/BodyComponents/skeletoncard/skeletoncard";
import { Pagination } from "@mui/material";
// import Pagination from "../../Components/Pagination";
import { useSnackbar } from "notistack";
import { addToCart } from "../../Services/Action/Action";
// import { addToWishlist } from "../../Services/Action/WishlistAction";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactHelmet from "../../Components/ReactHelmet/ReactHelmet";

const FilterPage = () => {
  const perPage = 20;

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const keyword = searchParams.get("q") ?? "";
  const category = searchParams.get("cat") ?? "";
  const currentPage = Number(searchParams.get("page")) || 1;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [highLow, setHighLow] = useState("");
  // const [min, setMin] = useState("");
  // const [max, setMax] = useState("");
  const [oldNew, setOldNew] = useState("");

  const handler = (e, get) => {
    dispatch(addToCart(get));
    enqueueSnackbar("Item added to cart", {
      variant: "success",
    });
  };

  // const handlerWish = (e, get) => {
  //   e.preventDefault();
  //   dispatch(addToWishlist(get));
  //   enqueueSnackbar("Item added to wishlist", {
  //     variant: "success",
  //   });
  // };

  const [loading, setLoading] = useState(false);
  // const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(currentPage);
  const handlePage = (e, value) => {
    e.preventDefault();
    setPage(value);
    navigate(`/filter?q=${keyword}&cat=${category}&page=${value}`);
    window.scrollTo({ top: 0 });
  };
  const [allData, setAllData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const getData = async () => {
    setLoading(true);
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/filter`,
      params: {
        price_filter: highLow,
        sort_filter: oldNew,
        // min_price: min,
        // max_price: max,
        name: keyword,
        category_slug: category,
      },
    })
      .then((res) => {
        setFilterData(res.data);
        setAllData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const filterHandler = (e) => {
    e.preventDefault();
    setPage(1);
    navigate(`/filter?q=${keyword}&cat=${category}&page=${1}`);
    getData();
  };

  const totalPages = Math.ceil(allData.length / perPage);

  useEffect(() => {
    if (currentPage && !isNaN(currentPage)) {
      setPage(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    const paginatedFilterData = allData.slice(
      (page - 1) * perPage,
      page * perPage,
    );

    setFilterData(paginatedFilterData);
  }, [allData, page]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, category]);

  const getPageTitle =
    keyword && category
      ? `${keyword} ${category.charAt(0).toUpperCase() + category.slice(1)}`
      : keyword
        ? `${keyword}`
        : category
          ? `${category.charAt(0).toUpperCase() + category.slice(1)}`
          : "All Products";

  return (
    <div className="py-16 bg-white">
      <ReactHelmet title={`${getPageTitle} - Search`} />
      <div className="container">
        <div className="flex justify-end mb-4">
          <Link to="/">
            <button
              type="button"
              className="py-2 flex gap-2 items-center justify-center px-4 rounded !w-fit !transition-colors !duration-300 border-[1px] !border-p hover:!bg-p !text-p hover:!text-white "
            >
              <i className="fa-solid fa-home -mt-[1px]"></i>{" "}
              <span>Back To Home</span>
            </button>
          </Link>
        </div>

        <div className="rounded shadow-sm p-6 border bg-[#FCFCFC]">
          <p className="text-lg font-bold text-p">
            {allData.length}{" "}
            <span className="text-green">
              {allData.length > 1 ? "items" : "item"}
            </span>
          </p>

          <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label>Sort Date:</label>
              <select
                className="h-[38px] border border-[#ced4da] rounded px-2 focus-visible:outline-none"
                onChange={(e) => setOldNew(e.target.value)}
              >
                <option value="">Choose</option>
                <option value="oldest">Oldest</option>
                <option value="newest">Newest</option>
              </select>
            </div>

            {/* <div className="flex flex-col">
              <label>Min Price:</label>
              <input
                className="form-control"
                onChange={(e) => setMin(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label>Max Price:</label>
              <input
                className="form-control"
                onChange={(e) => setMax(e.target.value)}
              />
            </div> */}

            <div className="flex flex-col">
              <label>Sort Price:</label>
              <select
                className="h-[38px] border border-[#ced4da] rounded px-2 focus-visible:outline-none"
                onChange={(e) => setHighLow(e.target.value)}
              >
                <option value="">Choose</option>
                <option value="high_to_low">High To Low</option>
                <option value="low_to_high">Low To High</option>
              </select>
            </div>
          </div>
          <div className="filter-btn">
            <button onClick={filterHandler}>Filter</button>
          </div>
        </div>
        <div className="grid grid-cols-2 min-[700px]:grid-cols-4 xl:grid-cols-5 gap-x-4 gap-y-4 pt-12">
          {filterData.length > 0 || loading ? (
            <>
              {loading ? (
                <>
                  <Skeletoncard />
                </>
              ) : (
                filterData.map((get, id) => {
                  return (
                    <div
                      className="group p-2 shadow-md duration-300 hover:bg-t border bg-white border-zinc-200 rounded flex flex-col gap-4"
                      key={id}
                    >
                      <div className="w-full aspect-square relative overflow-hidden">
                        <NavLink to={`/${get.category_slug}/${get.slug}`}>
                          <img
                            src={get?.image}
                            alt="featureproducts"
                            className="border border-stone-50 rounded-sm h-full w-full object-cover"
                          />
                        </NavLink>
                      </div>

                      <NavLink
                        to={`/${get.category_slug}/${get.slug}`}
                        className="flex-1 text-center"
                      >
                        <p className="text-sm xs:text-base font-medium line-clamp-2 text-ellipsis text-black group-hover:text-p duration-300">
                          {get?.name}
                        </p>
                      </NavLink>

                      <p className="cart-content-price text-base font-extrabold text-p text-center">
                        $ {get?.price.toFixed(2)}
                      </p>

                      {Number(get.in_stock) === 1 ? (
                        <div className="rounded bg-green transition-colors duration-500 hover:bg-[#15632f]">
                          <button
                            type="button"
                            className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm"
                            onClick={(e) => handler(e, get)}
                          >
                            <i className="fas fa-shopping-cart pe-1"></i>
                            ADD TO CART
                          </button>
                        </div>
                      ) : (
                        <div className="rounded bg-[#8c1818]">
                          <div className="flex w-full items-center justify-center text-white py-1.5 px-1 text-sm">
                            Out of Stock
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              )}
            </>
          ) : (
            <h5 className="no-items mb-5">No items to show</h5>
          )}
        </div>

        {loading === false && filterData.length > 0 && totalPages ? (
          <>
            <div className="pagination-main pt-10">
              <Pagination
                shape="rounded"
                count={totalPages}
                page={page}
                onChange={handlePage}
                color="primary"
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default FilterPage;
