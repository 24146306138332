import React from "react";
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from "../ui/sheet";
import { NavLink, useNavigate } from "react-router-dom";

const NavbarDrawer = (props) => {
  const { handleChange, categories, logout, isOpen, setIsOpen, user } = props;

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild className="min-[816px]:hidden">
        <button
          className="flex hover:bg-transparent min-[816px]:hidden text-white text-2xl items-center"
          aria-label="hamburger-menu"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
      </SheetTrigger>

      <SheetContent
        className="w-full xs:w-3/4 min-[816px] z-[100] bg-[#f5f0f0]"
        overlayClassName="min-[816px]:hidden"
      >
        <SheetTitle className="hidden"></SheetTitle>
        <div className="flex flex-col pt-6 gap-3.5">
          <ul className="mb-0">
            <li>
              <select
                className="h-10 text-base rounded text-green font-['Poppins'] pl-2.5 border border-gray-200 w-full appearance-none"
                aria-label="Default select example"
                onChange={(e) => {
                  setIsOpen(false);
                  handleChange(e);
                }}
              >
                <option disabled selected value="">
                  Select Category
                </option>
                {categories.map((category) => (
                  <option key={category.slug} value={category.slug}>
                    {category.name}
                  </option>
                ))}
              </select>
            </li>
          </ul>
          <ul className="flex flex-col gap-3.5 text-green text-base pl-1.5">
            <li className="w-fit" onClick={() => setIsOpen(false)}>
              <NavLink
                className="hover:text-p transition-colors duration-300"
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="w-fit" onClick={() => setIsOpen(false)}>
              <NavLink
                className="hover:text-p transition-colors duration-300"
                to="/about"
              >
                About
              </NavLink>
            </li>
            <li className="w-fit" onClick={() => setIsOpen(false)}>
              <NavLink
                className="hover:text-p transition-colors duration-300"
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
            <li className="w-fit" onClick={() => setIsOpen(false)}>
              <NavLink
                to="/cart"
                className="hover:text-p transition-colors duration-300"
              >
                Cart
              </NavLink>
            </li>
            <li>
              <div className="text-base">
                {user === null ? (
                  <div
                    className="flex gap-2.5 w-fit"
                    onClick={() => setIsOpen(false)}
                  >
                    <NavLink
                      to="/login"
                      className="hover:text-p transition-colors duration-300"
                    >
                      <span>
                        <i className="fas fa-user pr-2.5"></i>Sign in
                      </span>
                    </NavLink>
                    <NavLink
                      to="/signup"
                      className="hover:text-p transition-colors duration-300"
                    >
                      <span>
                        <i className="fa-solid fa-arrow-right-to-bracket pr-2.5"></i>
                        Sign up
                      </span>
                    </NavLink>
                  </div>
                ) : (
                  <div
                    className="flex gap-2.5 w-fit"
                    onClick={() => setIsOpen(false)}
                  >
                    <NavLink
                      to="/profile"
                      className="hover:text-p transition-colors duration-300"
                    >
                      <span>
                        <i className="fas fa-user pr-1"></i>
                        Profile
                      </span>
                    </NavLink>
                    <span>/</span>
                    <p
                      onClick={logout}
                      className="hover:text-p cursor-pointer text-green transition-colors duration-300"
                    >
                      <span>Logout</span>
                    </p>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default NavbarDrawer;
