import cardItems from "./Reducer";
import { combineReducers } from "redux";
import cardWished from "./WishlistReducer";
import uiReducer from "./uiReducer";

export const rootreducer = combineReducers({
  cardItems: cardItems,
  cardWished: cardWished,
  ui: uiReducer,
});
