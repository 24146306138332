import React from "react";
import { Helmet } from "react-helmet";

/**
 * @param {Object} props - The props object
 * @param {string} props.title - Title tag
 * @param {string} props.keywords - Keyword
 * @param {string} props.metaDescription - Description
 * @returns {JSX.Element} React component with specified props
 */
const ReactHelmet = (props) => {
  const metaDescription =
    props.metaDescription ||
    "Welcome to Nepaligarden.com, your one-stop online destination for Nepalese groceries and handicrafts! We are a premier online store dedicated to providing a convenient shopping experience for all your Nepalese food and artisanal needs.";
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={metaDescription} />
        {props.keywords && <meta name="keywords" content={props.keywords} />}

        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content="https://nepaligarden.com/nepali-garden-favicon.png"
        />

        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta
          name="twitter:image"
          content="https://nepaligarden.com/nepali-garden-favicon.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </div>
  );
};

export default ReactHelmet;
