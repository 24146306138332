import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { clearCart } from "../../Services/Action/Action";
import { useDispatch } from "react-redux";

const UpdateOrder = () => {
  const isFirstRun = useRef(true);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const paymentIntent = searchParams.get("payment_intent");

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCart());
    const updateOrderPayment = async () => {
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/order/stripe/update-order`,
        data: {
          pid: paymentIntent,
        },
      })
        .then((res) => {
          navigate(
            `/order-success?no=${res?.data?.data?.order_no?.split("#")?.[1]}`,
            { replace: true },
          );
        })
        .catch((err) => {
          enqueueSnackbar("Order update error", {
            variant: "error",
          });
          navigate("/");
        });
    };

    if (isFirstRun.current && paymentIntent) {
      updateOrderPayment();
      isFirstRun.current = false;
    }
  }, []);

  return (
    <div className="flex items-center justify-center bg-white">
      <div className="flex flex-col items-center rounded-md p-6 border shadow-sm my-16 bg-white">
        <div className="text-center text-p text-[26px] font-bold">
          <p>
            Processing <span className="text-green">Order</span>
          </p>
        </div>

        <div className="flex items-center w-full justify-center h-[240px]">
          <CircularProgress />
        </div>

        <p className="text-base text-gray-500">We are processing your order.</p>
        <p className="text-base text-gray-500">Please do not close the page.</p>
      </div>
    </div>
  );
};

export default UpdateOrder;
