import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import ReactHelmet from "../Components/ReactHelmet/ReactHelmet";
import { Modal } from "@mui/material";
const Resetpassword = ({ email, setEmail }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigate("/codeverification");
  };
  const handleShow = () => setShow(true);

  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const postReset = async (e) => {
    e.preventDefault();
    if (email === "") {
      setResponse({
        success: null,
        error: "Please enter your email",
      });
    } else {
      setLoading(true);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/user/sendVerificationCode`,
        data: {
          email: email,
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            setResponse({
              success: "Password Reset Email Has Been Sent!",
              error: null,
            });
            handleShow();
          } else {
            setResponse({
              success: null,
              error: "Invalid Credentials",
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            setResponse({
              success: null,
              error: error.response.data.message,
            });
          }
        });
      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  return (
    <div className="login py-8">
      <ReactHelmet title="Reset Password - Nepali Garden" />
      <div className="container">
        <div className="flex gap-4">
          <div className="hidden md:block w-1/2">
            <div className="register-image">
              <img src="images/register/reset.png" alt="" />
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="border shadow-sm rounded p-6">
              <div>
                <NavLink to="/login" className="go-to">
                  {"< "} Go to Login Page
                </NavLink>
              </div>
              <div className="login-title">
                <h6>Reset Password</h6>
                <p>
                  Enter your email below and we will send you the reset link
                </p>
              </div>
              {response.error ? (
                <>
                  <Alert severity="error">{response.error}</Alert>
                </>
              ) : null}
              <div>
                <div className="input-group mb-3 mt-4 relative">
                  <label
                    className="font-bold text-gray-700"
                    style={{ fontFamily: "Work Sans" }}
                    htmlFor=""
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="input-group-text absolute right-2 bottom-2">
                    <i class="fas fa-envelope text-zinc-400"></i>
                  </span>
                </div>
                <div className="button">
                  <LoadingButton
                    className="btn"
                    loading={loading}
                    variant="outlined"
                    onClick={postReset}
                  >
                    Send me the reset link
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={show}
            onClose={handleClose}
            aria-labelledby="reset"
            aria-describedby="reset"
          >
            <div className="bg-white rounded w-fit absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] px-4">
              <div className="reset-verification-modal">
                <div className="tick">
                  <p>
                    <i className="fa-solid fa-check"></i>
                  </p>
                </div>
                <div className="reset-topic">
                  <h3>Reset link Sent</h3>
                  <p>
                    Please check your email for the reset link and follow the
                    procedure
                  </p>
                </div>
                <button
                  className="rounded bg-green text-white h-10 px-6 transition-colors hover:bg-[#15632f]"
                  onClick={handleClose}
                >
                  Proceed
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
