import React, { useEffect } from "react";
import Parse from "html-react-parser";
import { useState } from "react";
import axios from "axios";
import AboutLoader from "./Loader";
import ReactHelmet from "../../Components/ReactHelmet/ReactHelmet";
const About = () => {
  const [about, setAbout] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/about-page/get`,
    })
      .then((res) => {
        setAbout(res.data.data[0]);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="about_page container py-16">
      <ReactHelmet title="About us - Nepali Garden" />
      <div className="text-center text-p text-[26px] font-bold">
        <p>
          About <span className="text-green">Us</span>
        </p>
      </div>

      {loading ? (
        <AboutLoader />
      ) : (
        <>
          {about?.image ? (
            <div className="about_image mt-[20px] object-cover object-center relative pb-[56.25%]">
              <img
                src={about?.image}
                alt="about us"
                className="rounded-md absolute top-0 left-0"
              />
            </div>
          ) : null}

          <div className="about_desc">
            {Parse(about?.description ? about.description : "")}
          </div>
        </>
      )}
    </div>
  );
};
export default About;
