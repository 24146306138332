import {
  CardElement,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
  LinkAuthenticationElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React from "react";
import { useSnackbar } from "notistack";
import countriesArray from "../../lib/countries";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "black" },
    },
  },
};

export default function StripePaymentForm({
  order,
  handleCheckoutDataChange,
  setIsAddressComplete,
  setIsLinkAuthenticationComplete,
  isPaying,
  billingAddress,
  setBillingAddress,
  setIsBillingAndShippingSame,
  isBillingAndShippingSame,
  setPaymentMethod,
  paymentMethod,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();
    order(e);
    // const cardElement = elements.getElement(CardElement);

    // const { error, token } = await stripe.createToken(cardElement);

    // if (!error) {
    //   try {
    //     console.log("Payment", token);
    //     order(e, token?.id);
    //   } catch (err) {
    //     console.log("Error", error.message);
    //   }
    // } else {
    //   enqueueSnackbar(error.message, {
    //     variant: "error",
    //   });
    // }
  };

  const getDefaultValues = () => {
    try {
      let localStorageData = localStorage.getItem("user");
      let userForStripe;
      if (localStorageData) {
        userForStripe = JSON.parse(localStorageData);
        return {
          email: userForStripe?.user?.email || "",
        };
      }
    } catch (err) {
      return {
        email: "",
      };
    }
  };

  const defaultValues = getDefaultValues();

  return (
    <form onSubmit={handleSubmit} className="stripe-form flex flex-col gap-4">
      <fieldset className="bg-white rounded px-4 py-2 !m-0">
        <LinkAuthenticationElement
          onChange={(event) => {
            setIsLinkAuthenticationComplete(event.complete);
            handleCheckoutDataChange({ email: event.value.email });
          }}
          options={{ defaultValues: { email: defaultValues.email } }}
        />
        <div className="flex items-center">Shipping Address</div>
        <AddressElement
          options={{
            mode: "shipping",
            allowedCountries: ["US"],
            autocomplete: { mode: "automatic" },
            fields: {
              phone: "always",
            },
            validation: {
              phone: { required: "always" },
            },
          }}
          onChange={(event) => {
            setIsAddressComplete(event.complete);
            handleCheckoutDataChange({
              name: event.value.name ?? "",
              phone: event.value.phone ?? "",
              city: event.value.address.city ?? "",
              line1: event.value.address.line1 ?? "",
              line2: event.value.address.line2 ?? "",
              postal_code: event.value.address.postal_code ?? "",
              state: event.value.address.state ?? "",
            });
          }}
        />
        <div className="flex items-center">Card Information</div>
        <PaymentElement
          onChange={(event) => {
            setPaymentMethod(event.value.type);
          }}
          options={{
            fields: {
              billingDetails: "never",
            },
          }}
        />
        <div className="form-check !my-0">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isBillingAndShippingSame}
            id="flexCheckDefault"
            onChange={(e) => {
              const checked = e.target.checked;
              setIsBillingAndShippingSame(checked);
            }}
          />
          <label
            className="!text-[#30313d] !font-normal pl-2"
            htmlFor="flexCheckDefault"
          >
            Billing info is same as shipping
          </label>
        </div>
        {!isBillingAndShippingSame && (
          <>
            <div className="flex items-center pt-4">Billing Address</div>

            <div className="flex flex-col py-[11px] pr-[15px]">
              <div className="flex flex-col mb-3">
                <div className="!text-[#30313d] text-[15px]">
                  Cardholder Name
                </div>
                <input
                  value={billingAddress.name}
                  className="px-3 bg-[#fafafa] rounded border border-[#e1e1e1] shadow-sm focus:outline-none focus:border-p transition-colors h-[45px]"
                  placeholder="Name"
                  onChange={(e) =>
                    setBillingAddress((prev) => {
                      return {
                        ...prev,
                        name: e.target.value,
                      };
                    })
                  }
                />
              </div>

              <div className="flex flex-col mb-3">
                <div className="!text-[#30313d] text-[15px]">
                  Address Line 1
                </div>
                <input
                  value={billingAddress.line1}
                  className="px-3 bg-[#fafafa] rounded border border-[#e1e1e1] shadow-sm focus:outline-none focus:border-p transition-colors h-[45px]"
                  placeholder="Street Address"
                  onChange={(e) =>
                    setBillingAddress((prev) => {
                      return {
                        ...prev,
                        line1: e.target.value,
                      };
                    })
                  }
                />
              </div>

              <div className="flex flex-col mb-3">
                <div className="!text-[#30313d] text-[15px]">
                  Address Line 2
                </div>
                <input
                  value={billingAddress.line2}
                  className="px-3 bg-[#fafafa] rounded border border-[#e1e1e1] shadow-sm focus:outline-none focus:border-p transition-colors h-[45px]"
                  placeholder="Apt., suite, unit number, etc. (optional)"
                  onChange={(e) =>
                    setBillingAddress((prev) => {
                      return {
                        ...prev,
                        line2: e.target.value,
                      };
                    })
                  }
                />
              </div>

              <div className="flex flex-col md:flex-row gap-3 mb-3 w-full">
                <div className="flex flex-col w-full md:w-1/2">
                  <div className="!text-[#30313d] text-[15px]">City</div>
                  <input
                    value={billingAddress.city}
                    className="px-3 bg-[#fafafa] rounded border border-[#e1e1e1] shadow-sm focus:outline-none focus:border-p transition-colors h-[45px]"
                    onChange={(e) =>
                      setBillingAddress((prev) => {
                        return {
                          ...prev,
                          city: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                  <div className="!text-[#30313d] text-[15px]">
                    State/Province/Region
                  </div>
                  <input
                    value={billingAddress.state}
                    className="px-3 bg-[#fafafa] rounded border border-[#e1e1e1] shadow-sm focus:outline-none focus:border-p transition-colors h-[45px]"
                    onChange={(e) =>
                      setBillingAddress((prev) => {
                        return {
                          ...prev,
                          state: e.target.value,
                        };
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-3 mb-3 w-full">
                <div className="flex flex-col w-full md:w-1/2">
                  <div className="!text-[#30313d] text-[15px]">Country</div>
                  <select
                    value={billingAddress.country}
                    onChange={(e) =>
                      setBillingAddress((prev) => {
                        return {
                          ...prev,
                          country: e.target.value,
                        };
                      })
                    }
                    className="px-3 bg-[#fafafa] rounded border border-[#e1e1e1] shadow-sm focus:outline-none focus:border-p transition-colors h-[45px] w-full appearance-none !font-[Poppins] text-[15px]"
                  >
                    {countriesArray.map(({ code, value }) => (
                      <option key={code} value={code}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                  <div className="!text-[#30313d] text-[15px]">ZIP</div>
                  <input
                    value={billingAddress.postal_code}
                    className="px-3 bg-[#fafafa] rounded border border-[#e1e1e1] shadow-sm focus:outline-none focus:border-p transition-colors h-[45px]"
                    onChange={(e) =>
                      setBillingAddress((prev) => {
                        return {
                          ...prev,
                          postal_code: e.target.value,
                        };
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {/* <CardElement options={CARD_OPTIONS} /> */}
      </fieldset>
      <button
        className="!m-0 !w-full !transition-colors !duration-300 !bg-amber-500 hover:!bg-amber-700 !text-black hover:!text-white !shadow-lg disabled:cursor-not-allowed disabled:opacity-50 !disabled:bg-amber-500"
        disabled={isPaying}
      >
        Pay
      </button>
    </form>
  );
}
