import React from "react";
import ReactImageMagnify from "react-image-magnify";

const ZoomImage = ({ smallImage, largeImage }) => {
  return (
    <div>
      <div className="w-full md:w-96 !h-96 hidden md:block">
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: "Zoomed Image",
              isFluidWidth: true,
              src: smallImage,
            },
            largeImage: {
              src: largeImage,
              width: 1200,
              height: 1800,
            },
            lensStyle: {
              backgroundColor: "rgba(0,0,0,0.2)",
            },
            enlargedImageContainerClassName:
              "!w-full md:!w-[384px] !h-[384px] rounded-lg border-2 border-slate-600 p-0 !left-[400px]",
            enlargedImageClassName: "min-w-[1200px]", // Note: Important to set the min width to the same as large image width
            className:
              "!w-full md:!w-[384px] !h-[382px] p-0 rounded border-[1px] !cursor-move",
            imageClassName: "!w-full md:!w-[384px] !h-[380px]",
          }}
        />
      </div>
      <img
        src={smallImage}
        className="w-full aspect-square md:hidden rounded-lg border-[1px] border-bgGray"
        alt="Product"
      />
    </div>
  );
};

export default ZoomImage;
