import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import NavbarDrawer from "./NavbarDrawer";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

const Navbar = (props) => {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const keywordURL = searchParams.get("q");
  const categoryURL = searchParams.get("cat");

  // const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [categories, setCategories] = useState([]);
  const counts = useSelector((state) => state.cardItems.totalCounts);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [keyword, setKeyword] = useState(keywordURL ?? "");
  const [categoryFilter, setCategoryFilter] = useState(categoryURL ?? "");

  useEffect(() => {
    if (!keywordURL) {
      setKeyword("");
      return;
    }
    setKeyword(keywordURL);
  }, [keywordURL]);

  useEffect(() => {
    if (!categoryURL) {
      setCategoryFilter("");
      return;
    }
    setCategoryFilter(categoryURL);
  }, [categoryURL]);

  const wishCount = useSelector((state) => state.cardWished.totalCounts);

  const searchProduct = (e) => {
    e.preventDefault();
    navigate(`/filter?q=${keyword}&cat=${categoryFilter}`);
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/category/getFeature`,
    })
      .then((res) => {
        let response = res.data.data;
        setCategories(response);
      })
      .catch((err) => {
        console.log(err, "error message");
      });
  }, []);

  const logout = () => {
    props.setUser(null);
    localStorage.setItem("user", null);
    navigate("/", { replace: true });
    enqueueSnackbar("You have been logged out", {
      variant: "success",
    });
  };

  const getCategoryName = (key) => {
    const category = categories?.find((cat) => cat.slug === key);
    return category?.name || "All Categories";
  };

  return (
    <>
      <div className="bg-green flex justify-center items-center p-2">
        <div className="text-white container">
          <h1
            className="font-extrabold text-[15px] uppercase text-center"
            style={{ animation: "blink 2s infinite" }}
          >
            Free shipping on orders over $49 to USA
          </h1>
        </div>
      </div>
      <div
        className="top_header bg-bgGray shadow-md"
        style={{ position: "sticky", top: "-1px", zIndex: 99 }}
      >
        <div className="container flex items-center justify-between sm:py-3.5 w-full gap-4 lg:gap-16">
          <div className="p-0 justify-center shrink-0 w-[64px] h-[64px] sm:w-[90px]">
            <Link to="/">
              <img
                src="/ng-logo.png"
                alt="logo"
                className="h-full w-full object-contain shrink-0"
              />
            </Link>
          </div>
          <form onSubmit={searchProduct} className="w-[90%] hidden sm:block">
            <div className="w-full flex">
              <input
                type="text"
                className="h-10 pl-6 p-2 form-control rounded-r-[0px!important]"
                placeholder="Search for products"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <div className="relative h-10 w-auto border border-[#ced4da] bg-white text-green rounded-none border-x-0 p-2 text-sm">
                <p className="w-full font-['Poppins'] whitespace-nowrap text-center px-3 pt-[1px] text-ellipsis">
                  {getCategoryName(categoryFilter)}
                </p>
                <select
                  value={categoryFilter}
                  className="h-10 absolute top-0 right-0 opacity-0 w-full border border-[#ced4da] appearance-none bg-white text-green font-['Poppins'] rounded-none border-x-0 min-w-1/3 p-2 text-sm focus:outline-none"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setCategoryFilter(e.target.value);
                  }}
                >
                  <option className="w-auto" value="">
                    All Categories
                  </option>
                  {categories.map((category) => (
                    <option
                      key={category.slug}
                      value={category.slug}
                      className="w-auto"
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <button
                type="submit"
                className="h-10 min-w-10 bg-green border border-green hover:border-hoverGreen hover:bg-hoverGreen transition-colors rounded-r-md"
              >
                <span className="text-white">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </span>
              </button>
            </div>
          </form>
          <div className="flex gap-4 items-center">
            <span
              className="text-black text-xl block sm:hidden cursor-pointer"
              onClick={() => {
                setShowMobileSearch(!showMobileSearch);
              }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </span>
            <div>
              {props.user === null ? (
                <span
                  className="text-black text-xl cursor-pointer block lg:hidden"
                  onClick={() => navigate("/login")}
                >
                  <i className="fas fa-user"></i>
                </span>
              ) : (
                <DropdownMenu>
                  <DropdownMenuTrigger className="focus:outline-none lg:hidden">
                    <span className="text-black text-xl">
                      <i className="fas fa-user"></i>
                    </span>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="lg:hidden">
                    <DropdownMenuItem
                      className="hover:text-p cursor-pointer"
                      onClick={() => navigate("/profile")}
                    >
                      Profile
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="hover:text-p cursor-pointer"
                      onClick={logout}
                    >
                      Logout
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
              <div className="hidden lg:flex gap-2 text-black text-base">
                {props.user === null ? (
                  <>
                    <p
                      className="cursor-pointer"
                      onClick={() => navigate("/login")}
                    >
                      SignIn
                    </p>
                    <p>|</p>
                    <p
                      className="cursor-pointer"
                      onClick={() => navigate("/signup")}
                    >
                      SignUp
                    </p>
                  </>
                ) : (
                  <>
                    <p
                      className="cursor-pointer"
                      onClick={() => navigate("/profile")}
                    >
                      Profile
                    </p>
                    <p>|</p>
                    <p className="cursor-pointer" onClick={logout}>
                      Logout
                    </p>
                  </>
                )}
              </div>
            </div>
            <span
              className="text-black text-xl cursor-pointer"
              onClick={() => {
                navigate("/cart");
              }}
            >
              <i className="fas fa-shopping-cart">
                <sup className="bg-white text-h rounded-full px-[5px] text-sm">
                  {counts}
                </sup>
              </i>
            </span>
            {/* <NavbarDrawer
              isOpen={isSheetOpen}
              setIsOpen={setIsSheetOpen}
              handleChange={(e) => setCategories(e.target.value)}
              categories={categories}
              logout={logout}
              user={props.user}
            /> */}
          </div>
        </div>

        {showMobileSearch && (
          <form
            onSubmit={searchProduct}
            className="container w-full flex flex-col xs:flex-row gap-2 xs:gap-0 pb-3 sm:hidden"
          >
            <input
              type="text"
              className="h-10 pl-6 p-2 form-control xs:rounded-r-[0px!important]"
              placeholder="Search"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div className="relative min-w-[138px] text-ellipsis overflow-hidden h-10 border border-[#ced4da] bg-white text-green rounded-md xs:rounded-none border-x-0 py-2 text-sm">
              <p className="font-['Poppins'] xs:text-center overflow-hidden text-ellipsis px-3 pt-[1px] whitespace-nowrap">
                {getCategoryName(categoryFilter)}
              </p>
              <select
                value={categoryFilter}
                className="h-10 absolute top-0 right-0 opacity-0 w-full border border-[#ced4da] appearance-none bg-white text-green font-['Poppins'] rounded-none border-x-0 min-w-1/3 p-2 text-sm focus:outline-none"
                aria-label="Default select example"
                onChange={(e) => {
                  setCategoryFilter(e.target.value);
                }}
              >
                <option className="w-auto" value="">
                  All Categories
                </option>
                {categories.map((category) => (
                  <option
                    key={category.slug}
                    value={category.slug}
                    className="w-auto"
                  >
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="h-10 w-10 min-w-10 self-end bg-green border border-green hover:border-hoverGreen hover:bg-hoverGreen transition-colors rounded-md xs:rounded-l-none"
            >
              <span className="text-black">
                <i className="fa-solid fa-magnifying-glass"></i>
              </span>
            </button>
          </form>
        )}
      </div>
    </>
  );
};
export default Navbar;
