import React from "react";
import { Link, useLocation } from "react-router-dom";

const OrderSuccess = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const orderNo = searchParams.get("no");

  return (
    <div className="flex bg-white items-center h-[calc(100vh-240px)] justify-center">
      <div className="flex border flex-col rounded-md gap-8 items-center text-sm bg-white p-6">
        <i className="fa-solid fa-circle-check text-[92px] text-green"></i>

        <div className="text-center text-base text-gray-500 font-semibold">
          {orderNo && <p>Order No: #{orderNo}</p>}
          <p>You order has been placed successfully.</p>
          <p>You will receive an email shortly.</p>
        </div>

        <Link to="/" className="text-base text-p font-bold hover:underline">
          Home
        </Link>
      </div>
    </div>
  );
};

export default OrderSuccess;
