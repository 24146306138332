import React, { useState, useEffect } from "react";
import axios from "axios";
import Parse from "html-react-parser";
import PrivacyLoader from "./Loader";
import ReactHelmet from "../../Components/ReactHelmet/ReactHelmet";

const Privacy = () => {
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/privacy/get`,
    })
      .then((res) => {
        setTerms(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="terms-main pb-4">
      <ReactHelmet title="Privacy - Nepali Garden" />
      <div className="container">
        <div className="text-center text-p text-[26px] font-bold">
          <p>
            Privacy <span className="text-green">Policy</span>
          </p>
        </div>
        <div className="terms-body">
          {loading ? (
            <PrivacyLoader />
          ) : (
            <>
              <h3>{terms[0]?.title}</h3>
              {Parse(terms[0]?.description ? terms[0].description : "")}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Privacy;
