import React from "react";
import { Link } from "react-router-dom";

export default function Summary({ items, informationAmount }) {
  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="text-center text-p text-[26px] font-bold">
        <p>
          Order <span className="text-green">Summary</span>
        </p>
      </div>

      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg">List of items</p>
        <Link to="/cart">
          <button
            type="button"
            className="py-1.5 flex gap-1 items-center justify-center px-3 rounded !w-full !transition-colors !duration-300 !bg-green hover:!bg-hoverGreen !text-white hover:!text-white !shadow-lg"
          >
            <i className="fa-solid fa-arrow-left"></i> Back To Cart
          </button>
        </Link>
      </div>

      <div className="flex flex-col gap-4 lg:overflow-y-scroll">
        {items?.map((item, index) => (
          <div
            key={index}
            className="flex flex-row gap-4 border-2 rounded-md bg-white p-2"
          >
            <img
              src={item.image}
              alt="images"
              className="w-[75px] aspect-square object-contain rounded"
            />

            <div className="text-sm flex flex-col justify-between w-full">
              <div className="text-sm flex flex-col gap-1 w-full">
                <p className="font-semibold text-base">{item.name}</p>
                <p className="text-zinc-500 font-medium">
                  ${item.price.toFixed(2)}
                </p>
              </div>
              <div className="flex flex-row w-full justify-between">
                <p>Quantity: {item.quantity}</p>
                <p className="font-semibold text-green">
                  Amount: ${(item.price * item.quantity).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="order-summary !bg-[#F7F7F7] !text-black !m-0 rounded flex flex-col gap-4">
        {/* <div className="clearfix">
            <p className="left">Shipping</p>
            <p className="right">$90</p>
          </div>
          <hr /> */}
        {/* <div className="clearfix">
            <p className="left">Shipping</p>
            <p className="right">$90</p>
          </div> */}
        {/* <hr /> */}
        <div className="flex flex-row justify-between font-normal text-sm">
          <p>Sub Total</p>
          <p>${(informationAmount.subTotal / 100).toFixed(2)}</p>
        </div>
        <div className="flex flex-row justify-between font-normal text-sm">
          <p>
            Shipping charge
            <span className="font-normal italic text-sm w-full">
              {informationAmount.subTotal < 4900
                ? " (Get free shipping on orders above $49)"
                : ""}
            </span>
          </p>
          <p>${(informationAmount.shipping / 100).toFixed(2)}</p>
        </div>
        {informationAmount.isFirstOrder && (
          <div className="flex flex-row justify-between font-normal text-sm">
            <p>Discount</p>
            <p>${(informationAmount.discount / 100).toFixed(2)}</p>
          </div>
        )}

        <hr />

        <div className="flex flex-row justify-between text-lg">
          <p>Total</p>
          <p>${(informationAmount.total / 100).toFixed(2)}</p>
        </div>
      </div>
      {/* <div>
        <div className="pop-up-buttom button">
          <button
            type="button"
            className="btn summary-btn"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            data-bs-whatever="@fat"
          >
            Check your Items
          </button>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog"></div>
        </div>
      </div> */}
    </div>
  );
}
