import React, { useState } from "react";
import StripeContainer from "./StripeContainer";
import { useSnackbar } from "notistack";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearCart } from "../../Services/Action/Action";

const Payment = ({
  paymentMethod,
  setPaymentMethod,
  handleCheckoutDataChange,
  checkoutData,
  setError,
  paymentIntentId,
  user,
  informationAmount,
}) => {
  const [isPaying, setIsPaying] = useState(false);
  const [isBillingAndShippingSame, setIsBillingAndShippingSame] =
    useState(true);
  const [billingAddress, setBillingAddress] = useState({
    name: "",
    state: "",
    city: "",
    line1: "",
    line2: "",
    postal_code: "",
    country: "US",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLinkAuthenticationComplete, setIsLinkAuthenticationComplete] =
    useState(false);
  const [isAddressComplete, setIsAddressComplete] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const stripe = useStripe();
  const elements = useElements();

  const placeOrder = async (e) => {
    e.preventDefault();
    const isAllBillingDataFilled =
      !!billingAddress.name &&
      !!billingAddress.state &&
      !!billingAddress.city &&
      !!billingAddress.line1 &&
      !!billingAddress.postal_code &&
      !!billingAddress.country;

    const elementSubmit = await elements.submit();
    if (
      !checkoutData.line1 ||
      !checkoutData.city ||
      !checkoutData.state ||
      !checkoutData.name ||
      !checkoutData.email ||
      !checkoutData.phone ||
      !checkoutData.postal_code ||
      !isAddressComplete ||
      !isLinkAuthenticationComplete ||
      !!elementSubmit?.error
    ) {
      enqueueSnackbar("Please fill all the required fields", {
        variant: "error",
      });
      return;
    }

    if (!isAllBillingDataFilled && !isBillingAndShippingSame) {
      enqueueSnackbar("Please fill all the Billing Info", {
        variant: "error",
      });
      return;
    }

    setIsPaying(true);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/placeorder`,
      data: {
        ...checkoutData,
        billingAddress,
        isBillingAndShippingSame,
        // address:
        //   checkoutData.street +
        //   "," +
        //   checkoutData.city +
        //   "," +
        //   checkoutData.state,
        user_id: user?.user?.id || null,
        // stripe_cus_id: user?.user?.stripe_cus_id || null,
        payment_method: paymentMethod,
        total_amount: informationAmount.total,
        // stripeToken: stripe_id,
        method: "stripe",
        pid: paymentIntentId,
        is_first_order: informationAmount.isFirstOrder,
      },
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    })
      .then(async (res) => {
        if (res.data.status) {
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: `${process.env.REACT_APP_WEBSITE_URL}/update-order`,
              payment_method_data: {
                billing_details: {
                  address: {
                    city: isBillingAndShippingSame
                      ? checkoutData.city
                      : billingAddress.city,
                    country: isBillingAndShippingSame
                      ? "US"
                      : billingAddress.country,
                    line1: isBillingAndShippingSame
                      ? checkoutData.line1
                      : billingAddress.line1,
                    line2: isBillingAndShippingSame
                      ? checkoutData.line2
                      : billingAddress.line2,
                    postal_code: isBillingAndShippingSame
                      ? checkoutData.postal_code
                      : billingAddress.postal_code,
                    state: isBillingAndShippingSame
                      ? checkoutData.state
                      : billingAddress.state,
                  },
                  name: isBillingAndShippingSame
                    ? checkoutData.name
                    : billingAddress.name,
                  email: checkoutData.email,
                  phone: isBillingAndShippingSame ? checkoutData.phone : "",
                },
              },
            },
          });

          if (error) {
            enqueueSnackbar("Failed to process payment", {
              variant: "error",
            });
            return;
          }

          enqueueSnackbar("Your order has been placed successfully", {
            variant: "success",
          });
        } else {
          setError("Failed to place order.Try Again later");
          enqueueSnackbar("Failed to place order.Try Again later", {
            variant: "error",
          });
          window.scrollTo(0, 0);
        }
      })
      .catch((e) => {
        setError("Failed to place order.Try Again later");
        window.scrollTo(0, 0);
        enqueueSnackbar("Failed to place order.Try Again later", {
          variant: "error",
        });
      });

    setIsPaying(false);
    setTimeout(() => {
      setError(null);
    }, 5000);
  };

  return (
    <div className="flex flex-col gap-4">
      {/* <label>Select Payment Method</label> */}

      {/* <div className="col-md-3">
          <div
            className={
              paymentMethod === "cod"
                ? "payment-section payment-active"
                : "payment-section"
            }
            onClick={() => setPaymentMethod("cod")}
          >
            <div>
              <img
                src="https://cdn-icons-png.flaticon.com/512/1554/1554401.png"
                alt="images"
                className=""
              />
            </div>
            <div className="payment-title">Cash On Delivery</div>
            <div className="payment-sub-title">Cash On Delivery</div>
          </div>
        </div> */}

      {/* Stripe  */}
      {/* <div
        className="flex flex-col gap-4 bg-white p-4 text-center font-bold rounded"
        onClick={() => setPaymentMethod("card")}
      >
        <img
          src="https://www.kindpng.com/picc/m/399-3995736_credit-card-payment-options-visa-mastercard-discover-logos.png"
          alt="images"
          className="object-contain"
        />
        <p>Credit/debit Card</p>
      </div> */}

      {/* <div className="col-md-3">
          <div
            className={
              paymentMethod === "esewa"
                ? "payment-section payment-active"
                : "payment-section"
            }
            onClick={() => setPaymentMethod("esewa")}
          >
            <img
              src="https://timeandupdate.com/wp-content/uploads/2019/10/esewa-logo-e1417844536234.png"
              alt="images"
              className=" "
            />

            <div className="payment-title">E-Sewa</div>
            <div className="payment-sub-title">E-Sewa</div>
          </div>
        </div> */}

      {/* <div className="col-md-3">
          <div
            className={
              paymentMethod === "paypal"
                ? "payment-section payment-active"
                : "payment-section"
            }
            onClick={() => setPaymentMethod("paypal")}
          >
            <div>
              <img
                src="https://logos-world.net/wp-content/uploads/2020/05/PayPal-Logo.png"
                alt="images"
                className=""
              />
            </div>
            <div className="payment-title">Paypal</div>
            <div className="payment-sub-title">Paypal</div>
          </div>
        </div> */}

      <StripeContainer
        order={placeOrder}
        handleCheckoutDataChange={handleCheckoutDataChange}
        setIsAddressComplete={setIsAddressComplete}
        setIsLinkAuthenticationComplete={setIsLinkAuthenticationComplete}
        isPaying={isPaying}
        billingAddress={billingAddress}
        setBillingAddress={setBillingAddress}
        setIsBillingAndShippingSame={setIsBillingAndShippingSame}
        isBillingAndShippingSame={isBillingAndShippingSame}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
    </div>
  );
};

export default Payment;
