import React, { useState, useEffect, useRef } from "react";
import Loginimg from "../Components/assets/images/login.png";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { Alert, LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showSuccessSnackbar } from "../Services/Action/snackbarActions";
import { useSnackbar } from "notistack";
import { ValidEmail } from "../helpers/Validators";
import ReactHelmet from "../Components/ReactHelmet/ReactHelmet";

const Login = (props) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [validationMessage, setValidationMessage] = useState({
    email: null,
    password: null,
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setResponse({
      error: null,
      success: location.state?.message,
    });
  }, [location]);

  const postLogin = async (e) => {
    e.preventDefault();
    if (email === "") {
      setValidationMessage({
        email: "Email Cannot be empty",
        password: null,
      });

      return;
    } else if (ValidEmail(email) === false) {
      setValidationMessage({
        email: "Invalid Email",
        password: null,
      });

      return;
    } else if (password === "") {
      setValidationMessage({
        email: null,
        password: "Password Cannot be empty",
      });
      return;
    } else {
      setLoading(true);
      setValidationMessage({ email: null, password: null });
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/user/login`,
        data: {
          email: email,
          password: password,
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            props.setUser(res.data.data);
            setResponse({
              success: "Login successful",
              error: null,
            });
            enqueueSnackbar(`Welcome, ${res.data.data.user.name}`, {
              variant: "success",
            });
            navigate("/");
            setEmail("");
            setPassword("");
          } else {
            setResponse({
              success: null,
              error: res.data.message,
            });
            enqueueSnackbar(res.data.message, {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            enqueueSnackbar(error.response.data.message, {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Server Error", {
              variant: "error",
            });
          }
        });
      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  return (
    <>
      <div className="login py-8">
        <ReactHelmet title="Login - Nepali Garden" />
        <div className="container">
          <div className="flex gap-4">
            <div className="hidden md:block w-1/2">
              <div className="login-image">
                <img src={Loginimg} alt="login" />
              </div>
            </div>
            <div className="w-full md:w-1/2 border shadow-sm rounded p-6">
              <div className="login-title">
                <h6>Sign in to your account</h6>
                <p>
                  Haven't registered yet?
                  <Link to="/signup" className="underline-click pl-1">
                    Click here to sign up
                  </Link>
                </p>
              </div>

              <form>
                <div type="submit">
                  <div className="input-group mt-4 relative">
                    <label
                      className="font-bold text-gray-600"
                      style={{ fontFamily: "Work Sans" }}
                      htmlFor=""
                    >
                      Email
                    </label>

                    <input
                      type="email"
                      className="form-control"
                      placeholder="name@example.com"
                      value={email}
                      style={
                        validationMessage.email
                          ? {
                              border: "1px solid red",
                              paddingRight: "2rem",
                            }
                          : {
                              paddingRight: "2rem",
                            }
                      }
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <span className="input-group-text absolute right-2 bottom-2">
                      <i className="fas fa-envelope text-zinc-400"></i>
                    </span>
                  </div>
                  {validationMessage.email ? (
                    <span className="error error-message">
                      {validationMessage.email}
                    </span>
                  ) : null}

                  <div className="input-group mt-4 relative">
                    <label
                      className="font-bold text-gray-600"
                      style={{ fontFamily: "Work Sans" }}
                      htmlFor=""
                    >
                      Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="***"
                      value={password}
                      style={
                        validationMessage.password
                          ? {
                              border: "1px solid red",
                              paddingRight: "2rem",
                            }
                          : {
                              paddingRight: "2rem",
                            }
                      }
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="input-group-text absolute cursor-pointer right-2 bottom-2 text-zinc-400"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={
                          showPassword ? "fas fa-eye" : "fas fa-eye-slash"
                        }
                      ></i>
                    </span>
                  </div>

                  {validationMessage.password ? (
                    <span className="error error-message">
                      {validationMessage.password}
                    </span>
                  ) : null}

                  <div className="form-check mt-4">
                    <label
                      className="font-bold text-gray-600"
                      style={{ fontFamily: "Work Sans" }}
                      htmlFor="flexCheckDefault"
                    >
                      Forgot your password?
                      <Link
                        to="/passwordreset"
                        className="underline-click pl-1"
                      >
                        Click here to reset
                      </Link>
                    </label>
                  </div>

                  <div className="button">
                    <LoadingButton
                      className="btn"
                      type="submit"
                      loading={loading}
                      variant="outlined"
                      onClick={postLogin}
                    >
                      Take me to my account
                    </LoadingButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
