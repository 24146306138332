import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Button, CircularProgress, Modal } from "@mui/material";
import OrderLoader from "./OrderLoader";
import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  Td,
  Th,
  Tr,
} from "../../../../Components/ui/table";
export default function OrderList({ user }) {
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [cancelLoading, setCancelLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [viewOrderModal, setViewOrderModal] = useState(false);
  useEffect(() => {
    setLoading(true);

    if (user) {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/order/orders-history`,
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      })
        .then((res) => {
          setAllOrders(res.data.data.Orders);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
          setLoading(false);
        });
    }
  }, [user, reload]);

  const cancelOrder = async () => {
    setCancelLoading(true);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/cancelorder/${selectedOrder?.id}`,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    })
      .then((res) => {
        if (res.data.status) {
          enqueueSnackbar("Your order has been canceled. ", {
            variant: "success",
          });
          setReload(!reload);
          setViewOrderModal(false);
        } else {
          enqueueSnackbar("Failed to cancel order.", {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar("Failed to cancel order.", {
          variant: "error",
        });
      });

    setCancelLoading(false);
  };

  let totalWithoutShipping = 0;

  return (
    <>
      <div className="" style={{ overflowX: "auto" }}>
        <h3 className="text-2xl font-bold text-p pb-3">My Orders</h3>

        <Table className="bg-white">
          <TableHeader>
            <Tr>
              <Th>S.N</Th>
              <Th>Order No</Th>
              <Th>Date</Th>
              <Th className="text-nowrap">Total Amount</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </TableHeader>
          <TableBody>
            {loading ? (
              <OrderLoader />
            ) : (
              allOrders.map((item, id) => (
                <Tr key={id}>
                  <Th scope="row">{id + 1}</Th>
                  <Td>{item.order_no}</Td>
                  <Td className="text-nowrap">
                    {item.created_at.split("T")[0]}
                  </Td>
                  <Td>${item.amount.toFixed(2)}</Td>
                  <Td className="capitalize">
                    {item.status === "pending" ? (
                      <div className="inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold border-transparent bg-s text-white">
                        {item.status}
                      </div>
                    ) : item.status === "canceled" ? (
                      <div className="inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold border-transparent bg-p text-white">
                        {item.status}
                      </div>
                    ) : (
                      <div className="inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold border-transparent bg-green text-white">
                        {item.status}
                      </div>
                    )}
                  </Td>
                  <Td>
                    {/* Button trigger modal */}
                    <button
                      type="button"
                      className="btn"
                      onClick={(e) => {
                        setViewOrderModal(true);
                        setSelectedItem(item.order_items);
                        setSelectedOrder(item);
                      }}
                    >
                      <span>
                        <i className="fa-solid fa-eye text-zinc-700 pl-2" />
                      </span>
                    </button>
                  </Td>
                </Tr>
              ))
            )}
          </TableBody>
        </Table>

        {/* Modal */}
        <Modal
          className="modal fade"
          open={viewOrderModal}
          onClose={() => {
            setViewOrderModal(false);
          }}
          aria-labelledby="view"
          aria-describedby="view"
        >
          <div className="bg-white rounded p-6 max-w-[100vw] absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
            <div
              className="absolute right-4 top-2 cursor-pointer"
              onClick={() => setViewOrderModal(false)}
            >
              X
            </div>
            <div className="modal-dialog">
              <h5 className="mb-3 text-2xl text-p font-semibold text-center">
                Ordered <span className="text-green">Items</span>
              </h5>
              <Table
                wrapperClassName="max-h-[70vh]"
                className="bg-white max-w-[100vw] border"
              >
                <TableHeader>
                  <Tr>
                    <Th>S.N</Th>
                    <Th>Item</Th>
                    <Th>Quantity</Th>
                    <Th className="text-nowrap">Unit Price</Th>
                    <Th>Total</Th>
                  </Tr>
                </TableHeader>
                <TableBody>
                  {selectedItem.map((item, id) => {
                    const totalPriceOfItem =
                      Number(item.qty) * Number(item.product?.price);
                    totalWithoutShipping += totalPriceOfItem;
                    return (
                      <Tr key={item.id}>
                        <Td>{id + 1}</Td>
                        <Td className="text-nowrap">{item.product?.name}</Td>
                        <Td>{item.qty}</Td>
                        <Td>${item.product?.price?.toFixed(2)}</Td>
                        <Td>${totalPriceOfItem.toFixed(2)}</Td>
                      </Tr>
                    );
                  })}
                  <Tr>
                    <Td colSpan={4}>Subtotal</Td>
                    <Td>${selectedOrder?.sub_total?.toFixed(2)}</Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={4}>Shipping</Td>
                    <Td>${selectedOrder?.shipping_amount?.toFixed(2)}</Td>
                  </Tr>
                  {!!selectedOrder?.is_first_order && (
                    <Tr>
                      <Td colSpan={4}>{`First Order Discount (10%)`}</Td>
                      <Td>
                        ${selectedOrder?.first_order_discount?.toFixed(2)}
                      </Td>
                    </Tr>
                  )}
                </TableBody>
                <TableFooter>
                  <Tr>
                    <Td colSpan={4}>Total</Td>
                    <Td>${selectedOrder?.amount?.toFixed(2)}</Td>
                  </Tr>
                </TableFooter>
              </Table>

              {/* {selectedOrder?.status === "pending" ? (
                <div className="flex items-center justify-center pt-2">
                  <Button
                    className="h-[30px]"
                    color="primary"
                    variant="contained"
                    size="small"
                    type="button"
                    onClick={cancelOrder}
                  >
                    {cancelLoading ? (
                      <CircularProgress className="!text-white !h-5 !w-5" />
                    ) : (
                      "Cancel Your Order"
                    )}
                  </Button>
                </div>
              ) : null} */}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
