import React from "react";
import LeftBarSide from "./LeftBarSide";
import ProfileRouting from "./ProfileRouting/ProfileRouting";

const Profile = (props) => {
  return (
    <div className="container">
      <div className="flex flex-col gap-4 py-16 md:flex-row">
        <div className="mb-4 w-60">
          <LeftBarSide />
        </div>
        <div className="w-full">
          <ProfileRouting user={props.user} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
