import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { Link } from "react-router-dom";
import BannerLoader from "./BannerLoader";
import ReactGA from "react-ga4";

const MainBanner = () => {
  const [allCarousels, setAllCarousels] = useState([]);
  const [featuredCategories, setFeaturedCategories] = useState([]);

  const [loading, setLoading] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: featuredCategories.length > 8 ? 8 : featuredCategories.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow:
            featuredCategories.length > 8 ? 8 : featuredCategories.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow:
            featuredCategories.length > 7 ? 7 : featuredCategories.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow:
            featuredCategories.length > 6 ? 6 : featuredCategories.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow:
            featuredCategories.length > 5 ? 5 : featuredCategories.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow:
            featuredCategories.length > 3 ? 3 : featuredCategories.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: true,
          autoplay: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const mainSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/carousel/get`,
      })
        .then((res) => setAllCarousels(res.data.data))
        .catch((e) => console.log(e.message));

      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/category/getFeature`,
      })
        .then((res) => setFeaturedCategories(res.data.data))
        .catch((e) => console.log(e.message));

      setLoading(false);
    };

    fetch();
  }, []);

  return (
    <div className="main-banner">
      {loading ? (
        <BannerLoader />
      ) : (
        <Slider
          {...mainSettings}
          className="px-[0px!important] hero"
          touchMove={false}
        >
          {allCarousels
            ?.filter((carousel) => !!carousel?.status)
            .map((item, id) => (
              <div className="carousel-item overflow-hidden" key={id}>
                <img
                  src={item.image}
                  className="block w-full min-h-full h-auto aspect-[2000/490] object-cover object-center"
                  alt="banner"
                />
              </div>
            ))}
        </Slider>
      )}

      <div className="xl:px-4 !max-w-[1920px] container">
        {featuredCategories && featuredCategories.length > 0 && (
          <div className="text-center text-p text-[26px] font-bold pt-6">
            <p>
              Our <span className="text-green">Categories</span>
            </p>
          </div>
        )}
        {/* <div className="main-topic mt-5">
          <p>
            Our <span>Collections</span>
          </p>
        </div> */}

        <div className="text-center mt-1 mb-4 flex flex-row overflow-x-scroll gap-4 no-scrollbar sm:hidden">
          {featuredCategories.map((item, id) => (
            <div key={id}>
              <div className="h-full group">
                <Link
                  to={`/filter?cat=${item.slug}`}
                  onClick={() => {
                    ReactGA.event({
                      category: "category_click",
                      action: item.name,
                    });
                  }}
                  className="no-underline flex items-center justify-center gap-2 flex-col h-full"
                >
                  <div className="h-[150px] w-[150px] overflow-hidden rounded-full">
                    <img
                      src={item.image}
                      alt="banner"
                      className="inline-block rounded-full object-cover h-full duration-500 w-[150px] mt-[10px] group-hover:scale-110"
                    />
                  </div>
                  <p className="text-sm font-semibold text-black group-hover:text-green duration-500">
                    {item.name}
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center my-12 mx-8 hidden sm:block">
          <Slider {...settings} className="text-center">
            {featuredCategories.map((item, id) => (
              <div key={id}>
                <div className="h-full group">
                  <Link
                    to={`/filter?cat=${item.slug}`}
                    onClick={() => {
                      ReactGA.event({
                        category: "category_click",
                        action: item.name,
                      });
                    }}
                    className="no-underline flex items-center justify-center gap-4 flex-col h-full"
                  >
                    <div className="h-[150px] w-[150px] overflow-hidden rounded-full">
                      <img
                        src={item.image}
                        alt="banner"
                        className="inline-block rounded-full object-cover h-full duration-500 w-[150px] group-hover:scale-110"
                      />
                    </div>
                    <p className="text-sm font-semibold text-black group-hover:text-green duration-500">
                      {item.name}
                    </p>
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
