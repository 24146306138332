import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
export default function RefundLoader() {
  return (
    <>
      <Stack>
        <Skeleton variant="text" width="25%" height={36} />
        <Skeleton variant="text" height={36} />
        <Skeleton variant="text" height={36} />
        <Skeleton variant="text" height={36} />
        <Skeleton variant="text" height={36} />
        <Skeleton variant="text" height={36} />
        <Skeleton variant="text" height={36} />
      </Stack>
    </>
  );
}
